jQuery(document).ready(function($){
    
    $('ul#header__desktop_menu >.menu-item a').on('focus', function(){
        hideAndShow($(this));
    });

    $('ul#header__desktop_menu >.menu-item a').on('mouseenter', function(){
        hideAndShow($(this));
    });

    $('ul#header__desktop_menu .sub-menu').on('mouseleave', function(){
        $('.sub-menu').hide();
    });

    function hideAndShow($element){
        $element.closest('.menu-item').siblings('.menu-item').find('.sub-menu').hide();
        if ($element.closest('.menu-item').hasClass('menu-item-has-children')){
            showSecondLevelMenuItems($element.closest('.menu-item-has-children').find('>.sub-menu'));
        }
    }

  
    function showSecondLevelMenuItems($element){
        $element.closest('.sub-menu').show();
        $element.show();
    }
});