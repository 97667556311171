(function ($) {
  $(function () {
    if (!$('.search-section').length) return;
    $('form.search-form').submit(function (e) {
      if ( $( this ).hasClass( 'mobile' ) ) {
        if (!$('input[id="search-term-mobile"]').val().trim().length) {
          // alert('Please enter a non-blank search.');
          $('.empty-search-warning-mobile').show();
          e.preventDefault();
        }
      } else {
        if (!$('input[id="search-term"]').val().trim().length) {
          // alert('Please enter a non-blank search.');
          $('.empty-search-warning').show();
          e.preventDefault();
        }
      }
    });
  });
})(jQuery);
